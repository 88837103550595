import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import default_img from "../../../assets/images/default.jpg";
import { useSelector } from "react-redux";

function Jobs() {
  const jobs = useSelector((state) => state.JobReducer.jobs);
  useEffect(()=>{
    console.log(jobs);
  },[jobs])

  return (
    <>
      <div className="card">
        <h5>Recent Joined Talents</h5>
        <DataTable value={jobs} rows={3} paginator responsiveLayout="scroll">
          <Column
            header="Logo"
            body={(data) => (

              <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{width: '3.5rem',height: "3.5rem"}}><i className="pi pi-desktop text-cyan-500 text-xl"></i></div>

            )}
          />
          <Column
            field="label"
            header="Label"
            sortable
            style={{ width: "35%" }}
            body={(data) => (
              <span

              >{data.job_title}</span>
            )}
          />
          <Column
            field="location"
            header="Location"
            sortable
            style={{ width: "35%" }}
            body={(data) => (
              <span

              >{data.job_location}</span>
            )}
          />
          <Column
            header="View"
            style={{ width: "15%" }}
            body={() => (
              <>
                <Button
                  icon="pi pi-search"
                  type="button"
                  className="p-button-text"
                />
              </>
            )}
          />
        </DataTable>
      </div>
    </>
  );
}


export default Jobs;
