import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";

import { useSelector, useDispatch } from "react-redux";
import { init_feedbacks } from "../../../redux/feedbacks/feedbacks.reducer";
import { init_enrolls } from "../../../redux/enroll/enroll.reducer";

import Pagination from "../../../MyComponents/Pagination/Pagination";

import DelEnrollUp from "./popups/DelEnrollUp";
import ShowUser from "./popups/ShowUser";
import SendMail from "./popups/SendMail";

import AvatarComponent from "../../../MyComponents/DataDisplay/Avatar";
import FilterComp from "./Filter";
import { GetAllEnroll } from "../../../redux/enroll/enroll.actions";

const init_filter = {
  // email:"",
  pays: "",
  suspended: "",
  verified: "",
};

const EnrollUp = () => {
  const dispatch = useDispatch();
  const enrolls = useSelector((state) => state.enrollsReducer.enrolls);

  const [Item, setItem] = useState({ ...init_enrolls });

  //-------------------------------- Pagin & Filter --------------------------------------------
  const [page, setPage] = useState({ p: 0, l: 10 });
  const max = 10;

  const [Filter, setFilter] = useState({ ...init_enrolls });
  //-------------------------------- Dialog States --------------------------------------------
  const [suppDialogue, setSuppDialog] = useState(false);
  const [shoSDialogue, setShoSDialog] = useState(false);
  const [updDialogue, setUpdtDialog] = useState(false);

  //-------------------------------- Get Data --------------------------------------------
  const reloadData = () => {
    dispatch(GetAllEnroll());
  };

  useEffect(() => {
    reloadData();
  }, [Filter]);

  useEffect(() => {
    console.log(enrolls);
  }, [enrolls]);
  //-------------------------------- Handle Opens -------------------------------

  const openUpdtDialogue = (row) => {
    setUpdtDialog(true);
    setItem({ ...row });
  };
  const openSuppDialogue = (row) => {
    setSuppDialog(true);
    setItem({ ...row });
  };

  const openShoSDialogue = (row) => {
    setShoSDialog(true);
    setItem({ ...row });
  };

  //-------------------------------- Handle Close -------------------------------
  const handleClose = () => {
    setItem({ ...init_enrolls });
    setSuppDialog(false);
    setShoSDialog(false);
    setUpdtDialog(false);
  };

  //-------------------------------- Header of Page -------------------------------

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <h5 className="m-0 mr-2">Manage Enrolls</h5>
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            icon="pi pi-refresh"
            className="p-button-primary mr-2"
            onClick={reloadData}
          />
        </div>
      </React.Fragment>
    );
  };

  //------------------------------------------- COLUMNS VALUES ----------------------------------------------

  const ShowMain = ({ row }) => {
    return (
      <div className=" flex ">
        <AvatarComponent
          src={null}
          circle={true}
          name={row?.User?.firstName || "N"}
          lastname={row?.User?.lastName || "N"}
        />
        <div className=" flex flex-column justify-content-center ml-2  ">
          <span className=" font-semibold ">{`${row?.User?.firstName || "N"} ${
            row?.User?.sureName || "N"
          }`}</span>
          <span className=" text-600 ">{row?.User?.email}</span>
        </div>
      </div>
    );
  };

  const ActionBodyTemplate = ({ row }) => {
    return (
      <div
        className="actions"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >

        <Button
          icon="pi pi-trash"
          tooltip="Delete Enroll"
          tooltipOptions={{ position: "bottom" }}
          className="p-button-rounded p-button-text p-button-danger mr-0"
          onClick={() => openSuppDialogue(row)}
        />
      </div>
    );
  };

  const ShowEnroll = ({ row }) => {
    return (
      <div className="col-12 xl:col-12 lg:col-12 md:col-12 sm:col-12 xs:col-12">
        <div
          className="card flex flex-column justify-content-between"
          style={{ height: "100%" }}
        >
          <div>
            <p className="pt-2 mb-0 text-justify">
              <strong>“</strong>
              {row.message}
              <strong>”</strong>
            </p>
          </div>
          <ActionBodyTemplate row={row} />
        </div>
      </div>
    );
  };

  return (
    <div className="grid crud-demo">
      <div className="col-12">
        <div className="card">
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          />
          {Item && suppDialogue && (
            <DelEnrollUp
              open={suppDialogue}
              handleClose={handleClose}
              value={Item}
              title={`Delete Enroll `}
            />
          )}
        </div>
        <div className="grid pb-2">
          {enrolls && enrolls.map((row, index) => {
            return <ShowEnroll key={index} row={row} />;
          })}
        </div>

        <Pagination max={max} onPageChange={setPage} />
      </div>
    </div>
  );
};

export default EnrollUp;
