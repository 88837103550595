import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAllJobs } from "../../../redux/jobs/jobs.actions";
import { GetAllTalents } from "../../../redux/talents/talent.actions";
import { GetAllCompanies } from "../../../redux/company/company.actions";
import { GetAllFeedbacks } from "../../../redux/feedbacks/feedbacks.actions";

function HeadCards() {
  const comps = useSelector((state) => state.CompanyReducer.companies);
  const feedbacks = useSelector((state) => state.feedbacksReducer.feedbacks);
  const talents = useSelector((state) => state.TalentReducer.talents);
  const jobs = useSelector((state) => state.JobReducer.jobs);
  const dispatch = useDispatch()
  const reloadData = () => {
    
      dispatch(GetAllJobs());
      dispatch(GetAllTalents());
      dispatch(GetAllFeedbacks());
      dispatch(GetAllCompanies());

  };

  useEffect(() => {
    reloadData();
  }, []);

  useEffect(()=>{
    console.log(
      jobs.length);
  },[comps,
    feedbacks,
    talents,
    jobs])

  return (
    <>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Compagnies</span>
              <div className="text-900 font-medium text-xl">{comps.length}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-orange-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-users text-orange-500 text-xl" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Jobs</span>
              <div className="text-900 font-medium text-xl">{jobs.length}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-green-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-dollar text-green-500 text-xl" />
            </div>
          </div>

        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">
                Talents
              </span>
              <div className="text-900 font-medium text-xl">{talents.length}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-building text-cyan-500 text-xl" />
            </div>
          </div>

        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Feedbacks</span>
              <div className="text-900 font-medium text-xl">{feedbacks.length}</div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-purple-100 border-round"
              style={{ width: "2.5rem", height: "2.5rem" }}
            >
              <i className="pi pi-comment text-purple-500 text-xl" />
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default HeadCards;
