const keys = {
  setComps: "setComp",
  setFormas: "setFormas",
  setDemands: "setDemands",
  payload: "company_payload",
  setSelUniv: "setSelUniv",
  setManager: "setManager",
  set_allManagers: "set_allManagers",
  all: "all_university",
};

export default keys;
