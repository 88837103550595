import React from "react";
import useWindowSize from "./useWindowSize";

function usePopupSize() {
  const size = useWindowSize();

  const PopupSize = () => {
    switch (size) {
      case "xl":
        return "500px";
      case "lg":
        return "500px";
      case "md":
        return "500px";
      case "sm":
        return "500px";
      case "xs":
        return "98%";
      default:
        return "80%";
    }
  };

  return PopupSize;
}

export default usePopupSize;
