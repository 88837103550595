import keys from "./enroll.keys";

export const init_enrolls = {
  id: "",
  starsNumber: 1,
  comment: "",
  seen: "",
  firstName: "", sureName: "", email: "", phone: "", _id: "" ,
};


export const InitialState = {
  payload: false,
  enrolls: [],
  enroll: { ...init_enrolls },
};

export const enrollsReducer = (state = { ...InitialState }, action) => {
  switch (action.type) {
    case keys.all:
      return { ...action.value };
    case keys.set_enrolls:
      return { ...state, enrolls: action.value, payload: false };
    case keys.set_enroll:
      return { ...state, enroll: action.value, payload: false };
    case keys.payload:
      return { ...state, payload: action.value };
    default:
      return state;
  }
};
