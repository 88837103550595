import keys from "./enroll.keys";
import { toast } from "react-hot-toast";
// import { useSelector } from "react-redux";
import axios from "../../custom/axios";
import { Mquery } from "../../functions/MakeQuery";

const GetAllEnroll = (query = {}) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.get(`/api/v1/enroll-rap/messages`, Mquery(query));
      dispatch({
        type: keys.set_enrolls,
        value: response.data.messages,
      });
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const DeleteEnroll = (feedback, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.delete(
        `/api/v1/enroll-rap/messages/${feedback._id}`
      );
      console.log(response);
      dispatch({
        type: keys.payload,
        value: false,
      });
      toast.success(`Feedback Deleted Successfully`);
      dispatch(GetAllEnroll());
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

const GetOneEnroll = (id, callback) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: keys.payload,
        value: true,
      });
      const response = await axios.get(`/api/v1/enroll-rap/messages/${id}`);
      console.log(response);
      dispatch({
        type: keys.set_enroll,
        value: response.data.data,
      });
      callback();
    } catch (error) {
      dispatch({
        type: keys.payload,
        value: false,
      });
    }
  };
};

export {GetAllEnroll,
  DeleteEnroll,
  GetOneEnroll};
